import React, { createContext, useContext, useState, useEffect } from 'react';

const AnswerContext = createContext();
const pyflaskBaseUrl = 'https://db.backend.lenrdashboard.com:5000';

export const AnswerContextProvider = ({ children }) => {
  const [answer, setAnswer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState('');

  // /search / select
  const getAnswer = async (query, doc) => {
    const key = `${query}:${doc}`;

    setLoading(true);

    const cachedAnswer = sessionStorage.getItem(key);
    if (cachedAnswer) {
      const parsedAnswer = JSON.parse(cachedAnswer);
      setAnswer(parsedAnswer);
      setLoading(false);
      return parsedAnswer;
    }

    const res = await fetch(`${pyflaskBaseUrl}/qa?q=${query}&doc=${doc}`, {
      method: 'GET',
    });

    const data = await res.json();
    console.log('qa answer', data)
    sessionStorage.setItem(key, JSON.stringify(data));
    setAnswer(data);
    setLoading(false);
  };

  return (
    <AnswerContext.Provider value={{ getAnswer, answer, doc, setDoc, loading }}>
      {children}
    </AnswerContext.Provider>
  );
};

export const useAnswerContext = () => useContext(AnswerContext);