import React from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';
import './loading.css';

export const Loading = () => (
  <div className="loading-container">
    <MagnifyingGlass
      className="loading-gif"
	    color="#57068c"
      glassColor="#eee6f3"
	    height={100}
	    width={100}
	    timeout={3000} //3 secs
	  />
  </div>
);