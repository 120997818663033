import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SearchBar } from './SearchBar';
import { useResultContext } from '../../contexts/ResultContextProvider';

export const Navbar = ({searchQuestion, setsearchQuestion}) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);
  const { setSearchTerm } = useResultContext();

  useEffect(() => {
    const storedActiveLink = localStorage.getItem('activeLink');
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveLink(null);
      localStorage.removeItem('activeLink');
    } else if (location.pathname.startsWith("/articles/")) {
      const searchT = location.pathname.split("/")[1]; // Get the searchT parameter from the URL
      setActiveLink(searchT);
      localStorage.setItem('activeLink', searchT);
    } else {
      setActiveLink(location.pathname.substring(1));
      localStorage.setItem('activeLink', location.pathname.substring(1));
    }
  }, [location.pathname]);

  const handleLinkClick = () => {
    setSearchTerm("");
    setsearchQuestion("");
  };

  return(
  <div className="p-[1.5vw] pb-0 flex flex-wrap justify-center md:justify-between items-center border-b-2 border-[#cbd5e0]">
    <Link to="/" 
      onClick={handleLinkClick}
      className="text-[#57068c] hover:text-[#ab82c5]">
        <div className="text-2xl font-bold p-[2vw] md:p-0">LENR Search Engine</div>
    </Link>
    <SearchBar searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion}/>
    {location.pathname != "/" && (
    <nav className="block w-full">
      <ul className="flex space-x-6">
        <li className={`text-gray-700 hover:text-[#8900e1] hover:border-b-2 hover:border-[#ab82c5] transition duration-300 ${
            activeLink === 'articles' ? 'text-[#8900e1] border-b-2 border-[#8900e1]' : ''}`}>
          <Link to={`/articles/${encodeURIComponent(searchQuestion)}`}>Articles</Link>
        </li>
        <li className={`text-gray-700 hover:text-[#8900e1] hover:border-b-2 hover:border-[#ab82c5] transition duration-300 ${
            activeLink === 'chatbot' ? 'text-[#8900e1] border-b-2 border-[#8900e1]' : ''}`}>
          <Link to="/chatbot">ChatBot</Link>
        </li>
        <li className={`text-gray-700 hover:text-[#8900e1] hover:border-b-2 hover:border-[#ab82c5] transition duration-300 ${
          activeLink === 'diagram' ? 'text-[#8900e1] border-b-2 border-[#8900e1]' : ''}`}>
          <Link to="/diagram">Diagram</Link>
        </li>
      </ul>
    </nav>
    )}
  </div>
  )
};