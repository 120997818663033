import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useResultContext } from '../../contexts/ResultContextProvider';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as ClearIcon } from '../../assets/clear.svg';

export const SearchBar = ({searchQuestion, setsearchQuestion}) => {
  const navigate = useNavigate();
  const defaultText = 'What is excess heat?';
  const { setSearchTerm, searchTerm, getResults } = useResultContext();
  const [text, setText] = useState('');

  useEffect(() => {
    if (text.trim() !== '') {
      setText(text.trim());
    } else if (searchTerm && searchTerm.trim() !== '') {
      setText(searchTerm.trim());
    } else {
    }
  }, [searchTerm]);

  const onSearch = () => {
    if (text == ""){
      setsearchQuestion(defaultText);
      setText(defaultText)
      setSearchTerm(defaultText);
      console.log("Search Term: " + defaultText);
      navigate(`/articles/${encodeURIComponent(defaultText)}`);
      getResults(searchTerm, 40);
    } else if (text.trim() == searchTerm) {
      console.log("Search Again. Do Nothing")
    } else {
      setsearchQuestion(text);
      setSearchTerm(text);
      console.log("Search Term: " + text);
      navigate(`/articles/${encodeURIComponent(text)}`);
      getResults(searchTerm, 40);
    }
  };

  return (
    <div className="relative flex items-center w-[90vw] md:w-[60vw] h-[6vh]">
      <input
        value={text}
        type="text"
        className="w-[90vw] md:w-[60vw] h-[5vh] bg-gray-100 border border-gray-300 rounded-full shadow-sm outline-none px-3 py-1 text-base text-gray-700 hover:border-gray-400"
        placeholder={defaultText}
        onChange={(e) => setText(e.target.value)}
      />
      <button type="button" className="absolute w-[4vh] h-[4vh] right-[2vh]" onClick={onSearch}>
        <SearchIcon className="w-[4vh] h-[4vh]" />
      </button>
      {text !== '' && (
        <button type="button" className="absolute w-[4vh] h-[4vh] right-[7vh]" onClick={() => setText('')}>
          <ClearIcon className="w-[4vh] h-[4vh]"/>
        </button>
      )}
    </div>
  );
};