import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useResultContext } from '../../contexts/ResultContextProvider';
import { Navbar } from '../Header/Navbar';
import { Loading } from '../ResultsEle/Loading';
import createPublishedYearDiagram from '../DiagramEle/yearDiag';
import createCoAuthorDiagram from '../DiagramEle/coauthorDiag';

export const Diagram = ({searchQuestion, setsearchQuestion}) => {
  const { results, loading, getResults, searchTerm, setSearchTerm } = useResultContext();
  const svgRefPublishYear = useRef(null);
  const svgRefCoauthor = useRef(null);
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    const width = svgRefPublishYear.current.clientWidth;
    const height = svgRefPublishYear.current.clientHeight;
    if (!loading && results?.response?.docs) {
      createPublishedYearDiagram(svgRefPublishYear, results.response.docs, width, height);
      createCoAuthorDiagram(svgRefCoauthor, results.response.docs, width, height);
    }
  }, [loading, results]);

  if(loading) return <Loading />;

  return (
    <>
      <Navbar searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion}/>
      <label className='ml-[1vw] mt-[1vh] relative inline-flex cursor-pointer select-none items-center'>
        <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} className='sr-only'/>
        <span className='flex items-center text-sm text-[#ab82c5]'> Co-author Graph </span>
          <span className={`mx-4 flex h-7 w-[50px] items-center rounded-full p-1 duration-200 ${
              isChecked ? 'bg-[#330662]' : 'bg-[#ab82c5]'
            }`}>
            <span className={`h-5 w-5 rounded-full bg-white duration-200 ${isChecked ? 'translate-x-[20px]' : ''}`}>
          </span>
        </span>
        <span className='flex items-center text-sm text-[#330662]'>Published Year Diagram</span>
      </label>
      <div className="flex flex-col m-[1vw] mt-[1vh] w-full md:w-[95vw] border border-[#cbd5e0]">
        <svg ref={svgRefPublishYear} className={`w-full h-[68vh] border-b border-[#ebeff3] ${isChecked ? 'hidden' : 'block'}`}></svg>
        <svg ref={svgRefCoauthor} className={`w-full h-[68vh] border-b border-[#ebeff3] ${isChecked ? 'block' : 'hidden'}`}></svg>
        <div className="text-sm mt-[0.5vh] p-1">The diagram provides a visual overview of all search results, making it easier to decipher insights at a glance. It allows for quick analysis and identification of patterns or trends within the data. This visualization aids in understanding complex relationships and highlights key information efficiently.</div>
      </div>
    </>
  );
};
