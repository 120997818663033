import React, { useState, useEffect } from 'react';
import { useAnswerContext } from '../../contexts/AnswerContextProvider';

const Article = ( { searchTerm, article, id, setOpenedArticle, setOpenArticleDisplay } ) => {

  const { getAnswer, answer, doc, setDoc, loading } = useAnswerContext();
  const [expanded, setExpanded] = useState(id < 5);

  const {
    title,
    abstract,
    all_authors,
    date_uploaded,
    document_link,
    keywords,
    paragraph,
    publisher,
    volume,
    year_published
  } = article;

  useEffect(() => {
    if (expanded && paragraph){
      getAnswer(searchTerm, paragraph);
    }
  }, [expanded, paragraph]);

  const displayAuthors = () => {
    if (all_authors && all_authors.length > 0) {
      if (all_authors.length <= 2) {
        return all_authors.join(', ');
      } else {
        return `${all_authors.slice(0, 2).join(', ')} and ${all_authors.length - 2} more`;
      }
    }
    return '';
  };

  const displayInfo = () => {
    if (publisher) {
      return `${publisher}, ${volume}, ${year_published}`;
    }
    return '';
  };

  const openPopup = (article) => {
    setOpenedArticle(article)
    setOpenArticleDisplay(true);
  };

  return (
    <div className="w-full p-2">
      <p className="text-sm font-bold text-[#330662] m-0">{displayInfo()}</p>
      <button className="pl-1 text-2xl text-[#330662] font-extrabold no-underline transition-all duration-300 m-0 bg-transparent border-none text-left cursor-pointer hover:underline" onClick={() => openPopup(article)}>
        {title}
      </button>
      <p className="pl-1 text-base text-[#6d6d6d] font-bold m-0">{displayAuthors()}</p>
      {loading ? (
        <p className="text-[#007bff]">Loading...</p>
      ) : (
        <>
          {expanded ? (
            <p className="ml-[1vw]">
              {String(paragraph).split(answer.answer).map((part, idx) => {
                const key = idx + 1;
                const elements = [];
                if (idx > 0) {
                  elements.push(
                    <span className="bg-[#d6d6d6] text-[#404040] text-sm p-0 m-0" key={`highlight-${key}`}>
                      {answer.answer}
                    </span>
                  );
                }
                
                elements.push(<span className="text-[#6d6d6d] text-sm p-0 m-0" key={`text-${key}`}>{part}</span>);

                return elements;
              })}
            </p>
          ) : (
            <button
              onClick={() => setExpanded(!expanded)}
              className="text-[#007bff] cursor-pointer mt-2"
            >
              {expanded ? 'Collapse' : 'Expand'}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Article;