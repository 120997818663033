import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ReactComponent as ClearIcon } from '../../assets/clear.svg';
import './articledisplay.css';

export const ArticleDisplay = ({article, setOpenArticleDisplay, setOpenedArticle}) => {
  // let { title } = useParams();
  // const { state } = useLocation();
  const {
    title,
    abstract,
    all_authors,
    date_uploaded,
    document_link,
    keywords,
    paragraph,
    publisher,
    volume,
    year_published
  } = article;

  const closePopup = () => {
    setOpenedArticle(null)
    setOpenArticleDisplay(false);
  };

  return (
    <div className='articledisplay'>
      <button type="button" className="close-icon" onClick={closePopup}>
        <ClearIcon/>
      </button>
      <div className='content'>
        <h2 className='title'>{title}</h2>
        {all_authors && (<p className='author-list'>{all_authors.join("; ")}</p>)}
        <p className='abstract'>{abstract}</p>
        <div>
          {date_uploaded && (<p><strong>Date Uploaded:</strong> {date_uploaded.split('T')[0]}</p>)}
          {document_link && (
            <p><strong>Document Link:</strong> <a href={document_link} target="_blank" rel="noreferrer">{document_link}</a></p>
          )}
          {keywords && (<p><strong>Keywords:</strong> {keywords.join(", ")}</p>)}
          <p><strong>Publisher:</strong> {publisher}</p>
          <p><strong>Volume:</strong> {volume}</p>
          <p><strong>Year Published:</strong> {year_published}</p>
          {/* Add more fields as needed */}
        </div>
      </div>
    </div>
  );
};