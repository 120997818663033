import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ResultContextProvider } from './contexts/ResultContextProvider';

import { Home } from './components/Pages/Home';
import { Articles } from './components/Pages/Articles';
import { ChatBot } from './components/Pages/ChatBot';
import { Diagram } from './components/Pages/Diagram';
import { NoPage } from './components/Pages/NoPage';
// import Article from './components/Article';

import { Footer } from './components/Footer';

import './global.css';

const App = () => {

  const [searchQuestion, setsearchQuestion] = useState("");

  return (
    <div>
      <ResultContextProvider>
      <div>
        <Routes>
          <Route exact path="/" element={<Home searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion} />} />
          <Route path="articles/:searchT" element={<Articles searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion} />} />
          <Route path="chatbot" element={<ChatBot searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion} />} />
          <Route path="diagram" element={<Diagram searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion} />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </div>
      </ResultContextProvider>
    </div>

  );
};

export default App;