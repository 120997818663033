import React, { useEffect } from 'react';
// import './chatbot.css';
import { Navbar } from '../Header/Navbar';

export const ChatBot = ({searchQuestion, setsearchQuestion}) => {

  return (
    <>
    <Navbar searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion}/>
    <div className="flex justify-center items-center w-full mt-[2vh]">
      <iframe
        className="w-[90vw] h-[70vh] md:h-[75vh] rounded-lg shadow-lg"
        src="https://chatfast.io/chat/4183ab73-9c05-4860-b199-5566d0c46744"
        title="Chatbot"
      ></iframe>
    </div>
    </>
  );
};