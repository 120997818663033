import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Article from '../ResultsEle/Article';
import { useResultContext } from '../../contexts/ResultContextProvider';
import { AnswerContextProvider } from '../../contexts/AnswerContextProvider';
import { Loading } from '../ResultsEle/Loading';
import { Facets } from '../ResultsEle/Facets';
import { ArticleDisplay } from '../ResultsEle/ArticleDisplay';
import { Navbar } from '../Header/Navbar';

export const Articles = ({searchQuestion, setsearchQuestion}) => {
  const { searchT } = useParams();
  console.log("Articles Param: " + searchT);
  const { results, loading, getResults, searchTerm, setSearchTerm } = useResultContext();
  const [ filteredResults, setFilteredResults] = useState([]);
  const [ selectedFilters, setSelectedFilters ] = useState({
    all_authors: [],
    publisher: [],
    year_published: [],
  });
  const [openArticleDisplay, setOpenArticleDisplay] = useState(false);
  const [openedArticle, setOpenedArticle] = useState(null);

  useEffect(() => {
    // Check if searchT is present, otherwise use searchTerm from context
    if (searchTerm !== ""){
      if (results.length == 0) {
        getResults(searchTerm, 40);
      } else if (searchTerm != searchT){
        setSearchTerm(searchT);
        getResults(searchT, 40);
      } else {
        console.log("No Need to Update")
      }
    } else if (searchT !== ""){
      console.log("From URL");
      setSearchTerm(searchT);
      getResults(searchT, 40);
    } else {
      console.log("Articles: neither searchTerm or url has content");
    }
    
  }, [searchT]);

  useEffect(() => {
    // Apply selected filters to the copied results
    var filteredDocs = results?.response?.docs || []
    if (selectedFilters.all_authors.length != 0 || selectedFilters.publisher.length != 0 || selectedFilters.year_published.length != 0){
      filteredDocs = filteredDocs.filter((doc) => {
        // Replace these conditions with your filter logic based on selectedFilters
        return (
          selectedFilters.all_authors.some((author) =>
            doc.all_authors.includes(author)) ||
          selectedFilters.publisher.includes(doc.publisher) ||
          selectedFilters.year_published.includes(doc.year_published)
        );
      });
    }
    setFilteredResults(filteredDocs);
  }, [selectedFilters, results]);

  if(loading) return <Loading />;

  return(
    <>
    < Navbar searchQuestion={searchQuestion} setsearchQuestion={setsearchQuestion}/>
    {openArticleDisplay && openedArticle !== null && (
      <ArticleDisplay
        article={openedArticle}
        setOpenArticleDisplay={setOpenArticleDisplay}
        setOpenedArticle={setOpenedArticle} />)
    }
    <div className="flex flex-wrap justify-start">
      <div className="hidden md:block md:w-1/4 p-4">
        {filteredResults.length > 0 && 
        (<Facets 
          all_authors={results?.facet_counts?.facet_fields.all_authors || [] }
          publisher={results?.facet_counts?.facet_fields.publisher || []}
          year_publsihed={results?.facet_counts?.facet_fields.year_published || []}
          setSelectedFilters={setSelectedFilters}
        />)
        } 
      </div>

      <div className='w-full md:w-3/4 p-4 mb-[4vh]'>
        {filteredResults.map((element, index) => (
          <AnswerContextProvider key={index}>
            <Article 
              key={index}
              searchTerm={searchTerm}
              article={element}
              id={index}
              setOpenedArticle={setOpenedArticle}
              setOpenArticleDisplay={setOpenArticleDisplay}
              />
          </AnswerContextProvider>
        ))}
      </div>
    </div>
    </>
  );
};
