import React, { useState, useEffect } from 'react';
import { useResultContext } from '../../contexts/ResultContextProvider';
import './facets.css';

export const Facets  = ({all_authors, publisher, year_published, setSelectedFilters}) => {

    const [isAuthorSectionExpanded, setAuthorSectionExpanded] = useState(false);
    const [isPublisherSectionExpanded, setPublisherSectionExpanded] = useState(false);

    const handleFilterChange = (filterType, value) => {
        // Toggle the filter value in the selectedFilters array
        setSelectedFilters((prevFilters) => {
          const updatedFilters = { ...prevFilters };
          const index = updatedFilters[filterType].indexOf(value);
    
          if (index === -1) {
            updatedFilters[filterType].push(value);
          } else {
            updatedFilters[filterType].splice(index, 1);
          }
    
          return updatedFilters;
        });
    };

    const resetFilters = () => {
        setSelectedFilters({
            all_authors: [],
            publisher: [],
            year_published: [],
          });
    };

    const extractValues = (facetData) => {
        const values = [];
        for (let i = 0; i < facetData.length; i += 2) {
            const author = facetData[i];
            const count = facetData[i + 1];
            if (count !== 0) {
              values.push([author, count]);
            }
        }
        return values;
    };

    return(
      <>
      <div className="facet-filter">

        {/* All_authors */}
        <h2 
            className={"heading"}
            onClick={() => setAuthorSectionExpanded(!isAuthorSectionExpanded)}>
            Author
        </h2>
        <div className="list">
          <ul>
            {extractValues(all_authors).slice(0, isAuthorSectionExpanded ? undefined : 4).map((authorData) => (
              <li key={authorData[0]} className="list-item">
                <label className="label">
                  <input
                      type="checkbox"
                      value={authorData[0]}
                      // checked={selectedFilters.all_authors.includes(
                      // authorData[0]
                      // )}
                      onChange={() =>
                      handleFilterChange('all_authors', authorData[0])
                      }
                  />
                  <span className="text">{authorData[0]}</span>
                  <span className="count">{authorData[1]}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
        {isAuthorSectionExpanded ? (
          <button className="show-button" id="show-less-button" onClick={() => setAuthorSectionExpanded(false)}>
            Show less
          </button>
        ) : (
          <button className="show-button" id="show-more-button" onClick={() => setAuthorSectionExpanded(true)}>
            Show more
          </button>
        )}

        {/* Publishers */}
        <h2 
            className={"heading"}
            onClick={() => setPublisherSectionExpanded(!isPublisherSectionExpanded)}>
            Publisher
        </h2>
        <div className="list">
          <ul>
            {extractValues(publisher).slice(0, isPublisherSectionExpanded ? undefined : 4).map((publisherData) => (
              <li key={publisherData[0]} className="list-item">
                <label className="label">
                  <input
                    type="checkbox"
                    value={publisherData[0]}
                  //   checked={selectedFilters.publisher.includes(
                  //     publisherData[0]
                  //   )}
                    onChange={() =>
                      handleFilterChange('publisher', publisherData[0])
                    }
                  />
                  <span className="text">{publisherData[0]}</span>
                  <span className="count">{publisherData[1]}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
        {isPublisherSectionExpanded ? (
          <button className="show-button" id="show-less-button" onClick={() => setPublisherSectionExpanded(false)}>
            Show less
          </button>
        ) : (
          <button className="show-button" id="show-more-button" onClick={() => setPublisherSectionExpanded(true)}>
            Show more
          </button>
        )}
      </div>
      {/* Reset button to clear filters */}
      <button 
      className="reset-button"
      onClick={resetFilters}>Reset Filters</button>
      </>
    
    );
}