import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from "react-router-dom";
// import { ResultContextProvider } from './contexts/ResultContextProvider';

import App from './App';
import './global.css';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
console.log("Version 1.2")

root.render(
    <HashRouter>
        <App />
    </HashRouter>
    );
