
import * as d3 from 'd3';

const createPublishedYearDiagram = (ref, data, w, h) => {

  const uniqueData = data.reduce((acc, curr) => {
    const existingIndex = acc.findIndex(item => item.article_index === curr.article_index);
    if (existingIndex === -1) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const groupedData = uniqueData.reduce((acc, curr) => {
    const yearIndex = acc.findIndex(item => item.year === curr.year_published);
    if (yearIndex === -1) {
      acc.push({ year: curr.year_published, value: 1 });
    } else {
      acc[yearIndex].value++;
    }
    return acc;
  }, []);
  
  const minYear = Math.min(...groupedData.map(item => item.year));
  const maxYear = Math.max(...groupedData.map(item => item.year));
  for (let year = minYear; year <= maxYear; year++) {
    const existingIndex = groupedData.findIndex(item => item.year === year);
    if (existingIndex === -1) {
      groupedData.push({ year, value: 0 });
    }
  }
  
  groupedData.sort((a, b) => a.year - b.year);

  // Display
  let svg = d3.select(ref.current)
  svg.selectAll("*").remove();
  var margin = {top: 20, right: 5, bottom: 20, left: 30},
  width = w - margin.left - margin.right,
  height = h - margin.top - margin.bottom;
  svg.attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");
  
  const x = d3.scaleBand()
    .domain(groupedData.map(d => d.year))
    .range([margin.left, width - margin.right])
    .padding(0.1);

  const y = d3.scaleLinear()
    .domain([0, d3.max(groupedData, d => d.value)])
    .nice()
    .range([height - margin.bottom, margin.top]);

  svg.append("g")
    .attr("class", "bars")
    .selectAll("rect")
    .data(groupedData)
    .enter()
    .append("rect")
    .attr("x", d => x(d.year))
    .attr("y", d => y(d.value))
    .attr("width", x.bandwidth())
    .attr("height", d => height - margin.bottom - y(d.value))
    .attr("fill", "steelblue");

  let numTicksX = Math.ceil((maxYear-minYear) / 5);
  const tickValuesX = Array.from({ length: numTicksX + 1 }, (_, i) => minYear + i * 5);
  const xAxis = d3.axisBottom(x).tickValues(tickValuesX);
  svg.append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0, ${height - margin.bottom})`)
    .call(xAxis);

  const maxCount = d3.max(groupedData, d => d.value);
  svg.append("g")
    .attr("class", "y-axis")
    .attr("transform", "translate(" + margin.left + ",0)")
    .call(d3.axisLeft(y).ticks(maxCount).tickFormat(d3.format("d")))
    .call(g => g.select(".domain").remove());
};

export default createPublishedYearDiagram;
