import * as d3 from 'd3';
import forceBoundary from 'd3-force-boundary';

const createCoAuthorDiagram = (ref, data, w, h) => {

    const uniqueData = data.reduce((acc, curr) => {
        const existingIndex = acc.findIndex(item => item.article_index === curr.article_index);
        if (existingIndex === -1) {
            acc.push(curr);
        }
        return acc;
    }, []);

    // Node
    const authorPapers = {};
    uniqueData.forEach((row) => {
        if (row.all_authors != null) {
            row.all_authors.forEach((author) => {
                const trimmedAuthor = author.trim();
                authorPapers[trimmedAuthor] = (authorPapers[trimmedAuthor] || 0) + 1;
            });
        }
    });
    // Links
    const coOccurrence = {};
    uniqueData.forEach((row) => {
        if (row.all_authors != null) {
            const authors = row.all_authors;
            for (let i = 0; i < authors.length - 1; i++) {
                for (let j = i + 1; j < authors.length; j++) {
                    let author1 = authors[i].trim();
                    let author2 = authors[j].trim();

                    const linkId = [author1, author2].sort().join('--');
                    coOccurrence[linkId] = (coOccurrence[linkId] || 0) + 1;
                }
            }
        }
    });
    const links = Object.entries(coOccurrence).map(([linkId, weight]) => {
        const [source, target] = linkId.split('--');
        return { source, target, weight };
    });
    const data2 = Object.entries(authorPapers)
        .map(([author, papers]) => ({ author, papers }))
        .sort((a, b) => b.papers - a.papers);

    const width = w;
    const height = h;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3.select(ref.current)
    svg.selectAll("*").remove();
    svg.attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; max-height: 100%;");

    const simulation = d3.forceSimulation(data2)
        .alpha(0.3)
        .force("link", d3.forceLink(links).id(d => d.author).distance(5))
        .force("boundary", forceBoundary(20, 20, width - 20, height - 20))
        .force('collision', d3.forceCollide().radius(d => d.papers * 6 > 30 ?  d.papers * 6 : 30))
        .on("tick", ticked);
    
    const link = svg.append("g")
        .selectAll()
        .data(links)
        .join("line")
        .attr("stroke", "black")
        .attr("stroke-opacity", 0.6)
        .attr("stroke-width", d =>d.weight);

    const node = svg.append("g")
        .attr("stroke", "#fff")
        .selectAll()
        .data(data2)
        .join("circle")
        .attr("r", d => d.papers*4)
        .style("fill", "rgb(6,59,140)")
        .on("mouseover", function handleMouseOver(event, d) {
            d3.select(this).style("fill", "rgb(59,140,6)"); // Change color on hover
        })
        .on("mouseout", function handleMouseOut(event, d) {
            d3.select(this).style("fill", "rgb(6,59,140)"); // Change back to original color on mouse out
        });
  
    const labels = svg.selectAll(".label")
        .data(data2)
        .enter().append("text")
        .attr("class", "label")
        .text(d => d.author)
        .style("text-anchor", "middle")
        .style("font-size", "10px")
        .style("fill", "black");

    node.call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));

    function ticked() {
        link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

        node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);

        labels
        .attr("x", d => d.x)
        .attr("y", d => d.y + d.papers * 1/3 + 15);
    }

    function dragstarted(event) {
        // if (!event.active) simulation.alphaTarget(0.3).restart();
        if (!event.active) simulation.alphaTarget(0.02).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
    }

    function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
    }

    function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
    }
};

export default createCoAuthorDiagram;

