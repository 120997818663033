import React, { createContext, useContext, useState } from 'react';

const ResultContext = createContext();
const solrBaseUrl = 'https://solr.backend.lenrdashboard.com:8983/solr/lenr1/select?q=';
const pyflaskBaseUrl = 'https://db.backend.lenrdashboard.com:5000';

export const ResultContextProvider = ({ children }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // /search / select
  const getResults = async (query, rows) => {
    setResults([]);
    setLoading(true);
  
    try {
      const res = await fetch(`${pyflaskBaseUrl}/search?q=${query}&rows=${rows}`, {
        method: 'GET',
      });
  
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
  
      const data = await res.json();
      console.log('search results', data);
      setResults(data);
    } catch (error) {
      console.error('Error fetching results:', error);
      // Handle the error, e.g., set an error state or display a message to the user.
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResultContext.Provider value={{ getResults, results, searchTerm, setSearchTerm, loading }}>
      {children}
    </ResultContext.Provider>
  );
};

export const useResultContext = () => useContext(ResultContext);

